<template>
  <div class="account">
    <div class="cover"></div>
    <div class="container">
      <div class="row">
        <div class="d-none d-lg-block col-lg-6 align-self-center">
          <h1 class="info">Reise, so wie du bist.</h1>
        </div>
        <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-2">
          <div class="card">
            <h4 class="card-title">Registrieren</h4>
            <form action="">
              <div class="register-container">
                <div class="form-group">
                  <span class="material-icons light left">email</span>
                  <input type="text" class="form-control icon-left lg" @blur="validateEmailInput(), checkIfExists()" v-model="credentials.email" placeholder="E-Mail"/>
                </div>
                <span class="validation-error" v-show="showEmailExistsError">Es gibt bereits einen Account mit dieser E-Mail Adresse.</span>
                <span class="validation-error" v-show="showEmailError">Geben Sie Ihre E-Mail Adresse an.</span>
              </div>
              <div class="register-container">
                <div class="form-group">
                  <span class="material-icons light left">person</span>
                  <input type="text" class="form-control icon-left lg"  @blur="validateNameInput" v-model="credentials.name" placeholder="Vor- und Nachname"/>
                </div>
                <span class="validation-error" v-show="showNameError">Geben Sie Ihren Vor- und Nachnamen ein.</span>
              </div>
              <div class="register-container">
                <div class="form-group">
                  <span class="material-icons light left">lock</span>
                  <input type="password" class="form-control icon-left lg"  @blur="validatePasswordInput" v-model="credentials.password" placeholder="Passwort"/>
                </div>
                <span class="validation-error" v-show="showPasswordError">Geben Sie ein Passwort ein. Das Passwort muss mindestens 8 Zeichen lang sein und mindestens eine Zahl enthalten.</span>
              </div>
              <div class="register-container">
                <div class="form-group">
                  <span class="material-icons light left">lock</span>
                  <input type="password" class="form-control icon-left lg"  @blur="validatePasswordRepeatInput" v-model="credentials.passwordRepeat" placeholder="Passwort wiederholen"/>
                </div>
                <span class="validation-error" v-show="showPasswordRepeatError">Die Passwörter stimmen nicht überein.</span>
              </div>
              <div class="form-check">
                <div class="form-check checkbox">
                  <input class="input-style form-check-input" type="checkbox" tabindex="0" v-model="credentials.agbAccepted" id="dataSecurity" @change="checkBoxChanged">
                  <label class="form-check-label" for="dataSecurity">Ja, ich akzeptiere die <router-link target="_blank" class="datasec" :to="{ name: 'Datenschutz'}">Datenschutzerklärung</router-link></label>
                </div>
                <span class="validation-error" style="margin-left: -20px;" v-show="showDataSecError">Sie müssen die Datenschutzerklärung akzeptieren.</span>
              </div>
              <div class="form-group">
                <button type="submit" @click="handleRegister" style="width: 100%;" class="btn btn-fill btn-focus-design">Registrieren</button>
              </div>
              <router-link class="btn btn-link" to="/login">Zurück zum Login?</router-link>

              <h6 class="mb-1 mt50">Sie sind ein*e Anbieter*in?</h6>
              <a href="https://cms.tripmeister.de/" target="_blank">Jetzt kostenlos registrieren & inserieren!</a>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer Margin -->
    <div class="footer-margin"></div>
  </div>
</template>

<script>
  import { csrf, emailExists } from '@/api/auth';
  import { validEmail, validatePassword } from '@/utils/validate';
  import { handleCollectionInvitation } from '@/api/collection';
  import { showLoader, hideLoader } from '@/utils/helpers';
  import Resource from '@/api/resource';
  const userResource = new Resource('users');

  export default {
    name: 'Register',
    data() {
      return {
       credentials: {
        name: "",
        email: "",
        password: "",
        passwordRepeat: "",
        agbAccepted: false,
        appId: this.$appId,
        sharekey: null,
        appUrl: window.location.origin,
      },
      showEmailExistsError: false,
      showNameError: false,
      showEmailError: false,
      showPasswordError: false,
      showPasswordRepeatError: false,
      showDataSecError: false,
      loader: false,
    };
  },
  beforeCreate() {
    /*Collection Invitation ...*/
    if(this.$route.query.sharekey){

      handleCollectionInvitation(this.$route.query.sharekey).then( response => {
        /*save the institution*/
        this.credentials.sharekey = response.data;
        this.$notify({
          duration: 2500,
          title: 'Einladung annehmen',
          text: 'Registrieren Sie sich um die Einladung zu einer Merkliste anzunehmen.'
        });

      });
    }
  },
  methods: { 
    showLoader,
    hideLoader,
    validEmail,
    validatePassword,
    handleCollectionInvitation,
    checkBoxChanged(origin){
      this.$emit('checkBoxChanged', this.checked);
    },
    validateNameInput(){
      if(this.credentials.name == "" || this.credentials.name.length<4){
        this.showNameError = true;
        return false;
      }
      this.showNameError = false;
      return true;
    },
    validatePasswordInput(){
      if(!this.validatePassword(this.credentials.password)){
        this.showPasswordError=true;
        return false;
      }else{
        this.showPasswordError=false;
        return true;
      }
    },
    validatePasswordRepeatInput(){
      if(this.credentials.password != this.credentials.passwordRepeat){
        this.showPasswordRepeatError=true;
        return false;
      }else{
        this.showPasswordRepeatError=false;
        return true;
      }
    },
    validateEmailInput(){
      if(!validEmail(this.credentials.email)){
        this.showEmailError=true;
        return false;
      }
      else{
        this.showEmailError=false;
        return true;
      }
    },
    validateDataSecAccepted(){
      if(!this.credentials.agbAccepted){
        this.showDataSecError=true;
        return false;
      }
      else{
        this.showDataSecError=false;
        return true;
      }
    },
    checkIfExists(){
      var theMail = {email: this.credentials.email};
      emailExists(theMail).then(response => {
        if(response.status == "204"){
          /*no user*/
          this.showEmailExistsError = false;
        }
        else if(response.status == "200"){
          /*a user exists*/
          this.showEmailExistsError = true;
        }
      });
    },
    handleRegister(e) {
      e.preventDefault();
      var nameValid = this.validateNameInput();
      var emailValid = this.validateEmailInput();
      var passwordValid = this.validatePasswordInput();
      var passwordRepeatValid = this.validatePasswordRepeatInput();
      var dataSecurityAccepted = this.validateDataSecAccepted();
      if(!nameValid || !emailValid || !passwordValid || !passwordRepeatValid || this.showEmailExistsError || !dataSecurityAccepted){
        return;
      }

      this.loader = this.showLoader(this.loader);

      csrf().then(() => {
        userResource.store(this.credentials).then(() => {

          this.$notify({
            duration: 6000,
            title: 'Registrierung erfolgreich!',
            text: 'Ihre Registrierung im Tripmeister war erfolgreich, bitte bestätigen Sie Ihre E-Mail Adresse.'
          });

          this.$router.push({name:'Login', query: { afterRegistration: true }});

        })
        .catch(() => {
         this.$notify({
          type: 'error',
          duration: 5000,
          title: 'Registrierung fehlgeschlagen!',
          text: 'Die Registrierung ist fehlgeschlagen. Bitte probieren Sie es zu einem späteren Zeitpunk noch einmal!'
        });
       }).finally(()=>{
         this.loader = this.hideLoader(this.loader);
       });
     });
    },
  }
}
</script>

<style lang="scss" scoped>
  @import '@/scss/_variables.scss';

  .validation-error{
    color:#E5321B;
    font-size: 13px;
    text-align: center;
  }

  .form-group {
    margin-bottom: 0px;
  }

  .register-container {
    margin-bottom: 1rem;
  }

  .checkbox{
    width: 100%;
    
    label {
      position: relative;
      text-align: left;
      cursor: pointer;
      width: 100%;
      font-size: 13px;

      &:before{
        content: '';
        position: absolute;
        left: -40px;
        -webkit-appearance: none;
        width: 20px;
        height: 20px;
        border: 1px solid rgba(58, 58, 58, 0.2);
        display: inline-flex;
        margin-right: 20px;
      }

      &:after{
        content: '';
        display: block;
        position: absolute;
        top: 2px;
        left: 9px;
        width: 6px;
        height: 14px;
        border: solid #FFFFFF;
        border-top-width: medium;
        border-right-width: medium;
        border-bottom-width: medium;
        border-left-width: medium;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        left: -33px;
      }

    }

    input[type="checkbox"]{
      opacity: 0;

      &:focus-visible + label::before {
        border: 2px dashed #E5321B !important;
        border-radius: 3px !important;
        outline: none !important;
      }

      + label::after {
        content: none;
      }

      &:checked{ 

        + label::before {
          background-color: #FFF;
        }

        + label::after{
          content: '';
          display: block;
          position: absolute;
          top: 2px;
          left: 9px;
          width: 6px;
          height: 14px;
          border: solid #E5321B;
          border-top-width: medium;
          border-right-width: medium;
          border-bottom-width: medium;
          border-left-width: medium;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          left: -33px;
        }
      }
    }
  }

  .datasec:hover, .datasec:focus {
    color: #990606;
  }
</style>
